/* imghvr-flip-*
   ----------------------------- */
[class^="imghvr-flip-"],
[class*=" imghvr-flip-"] {
  perspective: 50em;

  img {
    backface-visibility: hidden;
  }

  figcaption {
    opacity: 0;
  }

  &:hover {
    > img {
      opacity: 0;
    }

    figcaption {
      opacity: 1;
      transition-delay: $transition-duration * 0.4;
    }
  }
}

/* imghvr-flip-horiz
   ----------------------------- */
.imghvr-flip-horiz {
  figcaption {
    transform: rotateX(90deg);
    transform-origin: 0% 50%;
  }

  &:hover {
    img {
      transform: rotateX(-180deg);
    }

    figcaption {
      transform: rotateX(0deg);
    }
  }
}

/* imghvr-flip-vert
   ----------------------------- */
.imghvr-flip-vert {
  figcaption {
    transform: rotateY(90deg);
    transform-origin: 50% 0%;
  }

  &:hover {
    > img {
      transform: rotateY(-180deg);
    }

    figcaption {
      transform: rotateY(0deg);
    }
  }
}

/* imghvr-flip-diag-1
   ----------------------------- */
.imghvr-flip-diag-1 {
  figcaption {
    transform: rotate3d(1, -1, 0, 100deg);
  }

  &:hover {
    > img {
      transform: rotate3d(-1, 1, 0, 100deg);
    }

    figcaption {
      transform: rotate3d(0, 0, 0, 0deg);
    }
  }
}

/* imghvr-flip-diag-2
   ----------------------------- */
.imghvr-flip-diag-2 {
  figcaption {
    transform: rotate3d(1, 1, 0, 100deg);
  }

  &:hover {
    > img {
      transform: rotate3d(-1, -1, 0, 100deg);
    }

    figcaption {
      transform: rotate3d(0, 0, 0, 0deg);
    }
  }
}
