/* imghvr-fade-*
   ----------------------------- */
[class^="imghvr-fade"],
[class*=" imghvr-fade"] {
  figcaption {
    opacity: 0;
  }

  &:hover {
    figcaption {
      opacity: 1;
    }

    > img,
    figcaption {
      transform: translate(0, 0);
    }
  }
}

/* imghvr-fade
   ----------------------------- */
.imghvr-fade {
}

/* imghvr-fade-in-up
   ----------------------------- */
.imghvr-fade-in-up {
  figcaption {
    transform: translate(0, 15%);
  }
}

/* imghvr-fade-in-down
   ----------------------------- */
.imghvr-fade-in-down {
  figcaption {
    transform: translate(0, -15%);
  }
}

/* imghvr-fade-in-left
   ----------------------------- */
.imghvr-fade-in-left {
  figcaption {
    transform: translate(-15%, 0);
  }
}

/* imghvr-fade-in-right
   ----------------------------- */
.imghvr-fade-in-right {
  figcaption {
    transform: translate(15%, 0);
  }
}
