/* imghvr-hinge-*
   ----------------------------- */
[class^="imghvr-hinge-"],
[class*=" imghvr-hinge-"] {
  perspective: 50em;
  figcaption {
    opacity: 0;
    z-index: 1;
  }

  &:hover {
    img {
      opacity: 0;
    }

    figcaption {
      opacity: 1;
      transition-delay: $transition-duration * 0.6;
    }
  }
}

/* imghvr-hinge-up
   ----------------------------- */
.imghvr-hinge-up {
  img {
    transform-origin: 50% 0%;
  }

  figcaption {
    transform: rotateX(90deg);
    transform-origin: 50% 100%;
  }

  &:hover {
    > img {
      transform: rotateX(-90deg);
    }

    figcaption {
      transform: rotateX(0);
    }
  }
}

/* imghvr-hinge-down
   ----------------------------- */
.imghvr-hinge-down {
  img {
    transform-origin: 50% 100%;
  }

  figcaption {
    transform: rotateX(-90deg);
    transform-origin: 50% -50%;
  }

  &:hover {
    > img {
      transform: rotateX(90deg);
      opacity: 0;
    }
    figcaption {
      transform: rotateX(0);
    }
  }
}

/* imghvr-hinge-left
   ----------------------------- */
.imghvr-hinge-left {
  img {
    transform-origin: 0% 50%;
  }

  figcaption {
    transform: rotateY(-90deg);
    transform-origin: 100% 50%;
  }

  &:hover {
    > img {
      transform: rotateY(90deg);
    }

    figcaption {
      transform: rotateY(0);
    }
  }
}

/* imghvr-hinge-right
   ----------------------------- */
.imghvr-hinge-right {
  img {
    transform-origin: 100% 50%;
  }

  figcaption {
    transform: rotateY(90deg);
    transform-origin: 0 50%;
  }

  &:hover {
    > img {
      transform: rotateY(-90deg);
    }
    figcaption {
      transform: rotateY(0);
    }
  }
}
